import React, { useEffect } from "react"
import AccountInformation from "../../components/account/pages/account-information"
import { useTranslationContext } from "../../context/TranslationContext"

const canonicals = {
  ["en-US"]: "/account/account-information",
  ["de-DE"]: "/de/konto/kontoinformationen",
}

const AccountInformationPage = () => {
  const { setTranslationContext } = useTranslationContext()

  useEffect(() => {
    setTranslationContext({
      locale: "en-US",
      canonicals: canonicals,
    })
  }, [setTranslationContext])

  return <AccountInformation />
}

export default AccountInformationPage
